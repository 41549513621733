<template>
  <a-card>
    <template #title>
      <span>{{ title }}</span>
    </template>
    <template #extra>
      <a-button type="primary" style="margin-right: 20px" @click="handleCreatePlan" v-if="watchData">保存教案</a-button>
      <a @click="getBack">返回</a>
    </template>
    <div>
      <a-row :gutter="48">
        <a-col :md="8" :sm="24">
          <a-form-item label="名称">
            <a-input v-model:value="inputVal" :disabled="!watchData"/>
          </a-form-item>
        </a-col>
        <a-col :md="16" :sm="24">
          <a-form-item label="关联课件">
            <a-tag
            style="height: 32px; font-size: medium; padding-top: 3px;"
            :closable="watchData"
            :key="tag.id"
            @close="cancelRelate(tag)"
            v-for="tag in relateTagId">
            {{tag.name}}
            </a-tag>
            <a-button @click="handleAddDate" v-if="watchData">添加</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </a-card>
  <div class="editor-body">
    <lesson-plan-editor
    :readOnly="pageState.readOnly"
    @onOk="handleChange"
    :from="fromData"
    ref="sendText"
  />
  </div>
  <select-relate-teacher-mine-courseware-modal
    :visible="visibleValue"
    @onCancel="handleCancel"
    @ok="handleOk"
    :relate-courseware-ids="courseIds"
    :coursewareType="'teacher'"
    :relateType="'ppt'"
  />
</template>

<script setup>
import { ref, onMounted, onBeforeMount, reactive, toRaw, createVNode, watch, getCurrentInstance } from 'vue'
import LessonPlanEditor from '@/components/lesson-plan-editor/LessonPlanEditor.vue'
import { createTeacherCourseware, createSpaceNode } from '../service'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import * as service from '../service'
import SelectRelateTeacherMineCoursewareModal from '@/views/courseware/components/SelectRelateTeacherMineCoursewareModal.vue'
import {
  STAT_EVENT,
  mineCoursewareStatResport
} from '@/utils/common/statReport'

const currentInstance = getCurrentInstance()
// 标题
const title = ref('新建教案')
// 可见度
const watchData = ref(false)

const route = useRoute()
const router = useRouter()
// 名称输入框
const inputVal = ref('')
const originContent = ref('')

// tag显示的id
const relateTagId = ref([])

// 关联弹窗可见
const visibleValue = ref(false)

// 内容 HTML
const valueHtml = ref('')
// 创建、编辑教案内容
const textValue = ref()
// 判断富文本的可读状态
const pageState = reactive({
  readOnly: route.params.action === 'preview'
})

// 课件关联的id
const courseIds = ref([])

const fromData = ref('teacher')

const changeData = ref(true)

onBeforeMount(() => {
  if (route.params.action === 'new') {
    title.value = '新建教案'
    watchData.value = true
  } else if (route.params.action === 'preview') {
    title.value = '预览教案'
    watchData.value = false
  } else {
    title.value = '编辑教案'
    watchData.value = true
  }
})

onMounted(async () => {
  if (route.params.action === 'new') {
    if (route.params.template !== '0') {
      // 获取关联教案模板
      const info = await service.getOfficialPlan(route.params.template, 'official')
      valueHtml.value = info.content
    }
  } else {
    const data = await service.getTeacherCourseware(route.params.id)
    const app = data.relate_coursewares.map(({ id, name }) => ({ id: id, name: name }))
    const item = data.relate_coursewares.map((i) => i.id)
    relateTagId.value = app
    courseIds.value = item
    inputVal.value = data.name
    textValue.value = data.plan_content
    valueHtml.value = data.plan_content
  }
  watch([inputVal, courseIds], () => {
    changeData.value = false
  })
})

const showConfirm = () => {
  Modal.confirm({
    title: '有尚未保存的教案内容，确定要离开吗?',
    icon: createVNode(ExclamationCircleOutlined),
    onOk () {
      removeData()
    }
  })
}

const removeData = () => {
  router.push('../../../../../mine-coursewares')
  localStorage.removeItem('relate_ppts')
}

// 返回
const getBack = () => {
  currentInstance.refs.sendText.handleSaveText()
  if (changeData.value === false) {
    showConfirm()
  } else if (route.params.action === 'new' && originContent.value === false) {
    showConfirm()
  } else if (route.params.action === 'edit' && textValue.value !== valueHtml.value) {
    showConfirm()
  } else {
    removeData()
  }
}

// 创建教案
const handleCreatePlan = async () => {
  textValue.value = valueHtml.value
  currentInstance.refs.sendText.handleSaveText()
  if (inputVal.value === '') {
    message.error('教案名称为空')
    return
  }
  if (textValue.value === '<p><br></p>') {
    message.error('教案内容为空')
    return
  } else if (textValue.value === '') {
    message.error('教案内容为空')
    return
  }
  // 先创建课件，再创建节点
  const params = {
    name: inputVal.value,
    plan_content: textValue.value,
    type: 'plan',
    size: '',
    url: '',
    md5: '',
    uuid: '',
    relate_courseware_ids: courseIds.value
  }
  if (route.params.action === 'edit') {
    await service.editTeacherCourseware(route.params.id, params)
    mineCoursewareStatResport(STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_EDIT, {
      courseware_id: route.params.id,
      courseware_type: 'plan'
    })
    message.success('保存成功')
    removeData()
    return
  }
  try {
    const { id } = await createTeacherCourseware(params)
    mineCoursewareStatResport(STAT_EVENT.PREPARE_LESSON_MINECOURSEWARES_CREATE, {
      courseware_id: id,
      courseware_type: 'plan'
    })
    const nodeParams = {
      name: inputVal.value,
      type: 1,
      parent_id: route.params.node,
      sort: 1,
      relation_id: id
    }
    await createSpaceNode(nodeParams)
    message.success('保存成功')
    removeData()
  } catch (error) {
    console.log(error)
    removeData()
  }
}

// 弹窗确定按钮
const handleOk = () => {
  visibleValue.value = false
  const relate_ppts = JSON.parse(localStorage.getItem('relate_ppts'))
  console.log('relate_ppts', relate_ppts)
  relateTagId.value = relate_ppts.map((i) => { return { id: i.courseware?.id, name: i.name } })
  courseIds.value = relate_ppts.map((i) => i.courseware?.id)
  console.log('zheshi', courseIds.value)
}

// 富文本发生变化
const handleChange = (data) => {
  valueHtml.value = data
  originContent.value = false
}

const handleCancel = () => {
  visibleValue.value = false
}

// 添加按钮弹窗
const handleAddDate = () => {
  visibleValue.value = true
}

// tag取消调用
const cancelRelate = (tag) => {
  relateTagId.value = relateTagId.value.filter((list) => list.id !== tag.id)
  const relate_ppts = relateTagId.value
  courseIds.value = relate_ppts.map((i) => i.id)
  localStorage.setItem('relate_ppts', JSON.stringify(relate_ppts))
}

</script>

  <style lang="less" scoped>

  .editor-body {
    background-color: #fff;
    margin-top: 10px;
    padding: 20px 0 60px 30px;
    width: 100%;
    height: 100%;
  }

  </style>
